<template>
    <v-container>
        <v-row>
            <v-col cols="12" sm="1" >
                <div  
                    style="width: 100%; max-width: 100%; height: 100%; max-height: 100%;">
                    <div class="justify-center align-center text-center" >
                            <v-img :src="item.project.src" />
                    </div>
                </div>
            </v-col>
            <v-col cols="12" sm="3">
                <p>{{ item.project.title}}</p>
                <v-btn :to="item.project.$route.view" text>
                    <img
                    height="24"
                    width="24"
                    src="@/assets/i-play.png"
                    />
                    <span class="ml-1">Visualizar Obra</span>
                </v-btn>
            </v-col>

            <v-col cols="12" sm="4">
                <p class="primary--text text-uppercase">Avaliação Geral</p>
                <div v-html="item.generalEvaluation"></div>
            </v-col>

            <v-col cols="12" sm="4">
                 <p class="primary--text text-uppercase">Observações</p>
                 <div v-html="item.obs"></div>
            </v-col>

           
        </v-row>
    </v-container>
</template>
<script>
export default {
    props: {
        item: {
            type: Object
        }
    },
    data: () => ({
    }),
    methods: {
        submit() {
            const valid = false;

            if (!valid) {
                alert('Por favor faça avaliação e observação');
                return;
            }

            //api integration
        }
    }
}
</script>
<template>
    <v-container style="margin: 0; padding: 0">
        <v-row>
            <v-col cols="12">
                <v-subheader class="headline"><span class="primary--text">PAINEL JÚRI > </span> Festival</v-subheader>
                  <p-breadcrumbs page="profile.jury-panel.festival.home" />
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-alert
                    outlined
                    type="warning"
                    prominent
                    border="left"
                    v-if="items.length == 0"
                >
                    Lamentamos, mas ainda não tem nenhum projecto registado.
                </v-alert>
            </v-col>
        </v-row>
        
        <div class="body pl-2x pr-2x pt-1x pb-1x" >
            <div>
                <v-row>
                    <v-col cols="12" md="2"><span class="primary--text">Organizar Por: </span></v-col>
                    <v-col cols="12" md="4">
                        <v-select
                            :items="sortItems"
                            dense
                            item-text="label"
                            item-value="type"
                            v-model="sortOrder"
                            outlined
                            ></v-select>
                    </v-col>

                </v-row>
            </div>
            <template v-for="(item,i) in items">
                <v-divider v-if="(i+1)%2 === 0" class="ml-2x mr-2x" :key="`d-${item.project.id}`"/>
                <Project :item="item"  :key="item.project.id"/>
                
            </template>
        </div>

        <!--v-container v-if="hasMore">
            <v-row align="center" justify="center">
                <v-btn 
                :loading="loading" 
                :disabled="loading"
                @click="fetchMore">Carregue Mais</v-btn>
            </v-row>
        </v-container-->
    </v-container>
</template>
<script>
import { HOME } from '../../../../routes';
import Project from '../../../../components/author/jury-panel/festival/Project';
import { mapState, mapActions } from 'vuex';
export default {
    components: {
        Project,
    },
    data: () => ({
        sortOrder: { type: 'asc', label: 'A-Z' },
        sortItems: [
            { type: 'asc', label: 'A-Z' },
            { type: 'desc', label: 'Z-A'},
        ],
    }),
    created: function () {
        if (this.$user.isGuest) {
            this.$router.push({path: HOME}); //TODO check auth with globar guard
            return;
        }
        this.fetchItems(this.filter);
    },
    computed: {
        ...mapState('juryPanel/festival', ['items', 'loading',  'hasMore']),
        filter() {
            return { 
                limit: 20,
                id: this.$user.info.id,
            }
        },
        user() {
            if (this.$user.isAuthenticated)
                return this.$user.info;
            return null;
        },
    },
    methods: {
        ...mapActions('juryPanel/festival', {
            fetchItems: 'list',
            loadMore: 'loadMore',
        }),
        fetchMore() {
            this.loadMore(this.filter);
        },

    }
}
</script>